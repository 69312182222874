import { Box, Heading, Text } from '@chakra-ui/layout';
import React, { forwardRef } from 'react';
import { headerHeightInPx } from './HeaderBar';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import CTA from './CtaButton';
import { BoxProps } from '@chakra-ui/layout';
import { Flex } from '@chakra-ui/layout';

export interface IHero {
  heading?: string;
  text?: string;
  ctaText?: string;
  imgSrc: IGatsbyImageData;
  copyright?: string;
  isFirstOne?: boolean;
  imgPos?: string;
  filter?: string;
  isUnsplash?: boolean;
  boxProps?: BoxProps;
  textBg?: boolean;
  [key: string]: any;
}

const Hero = (
  {
    heading,
    text,
    ctaText,
    imgSrc,
    copyright,
    imgPos,
    isFirstOne,
    isUnsplash,
    boxProps,
    textBg,
    filter = '',
    ...rest
  }: IHero,
  ref,
) => {
  const image = getImage(imgSrc);
  return (
    <Box
      as="section"
      pos="relative"
      height={`calc(100vh - ${headerHeightInPx}px)`}
      minHeight={['200px', '600px']}
      maxHeight={'1000px'}
      overflow="hidden"
      {...boxProps}
      ref={ref}
      {...rest}
    >
      <Box
        pos="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display={['none', 'block']}
      >
        <GatsbyImage
          image={image as IGatsbyImageData}
          alt="Header"
          style={{ height: '100%', width: '100%', filter }}
          objectPosition={imgPos}
          objectFit="cover"
        />
      </Box>
      <Box
        pos="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display={['block', 'none']}
        sx={!textBg ? { filter: 'brightness(0.6)' } : {}}
      >
        <GatsbyImage
          image={image as IGatsbyImageData}
          style={{ height: '100%', width: '100%' }}
          alt="Header"
          objectPosition={imgPos}
          objectFit="cover"
        />
      </Box>
      {(heading || text) && (
        <Box
          as="article"
          pos="absolute"
          right={[0, textBg ? 0 : 10]}
          left={['auto', 'auto']}
          bottom={'50px'}
          w={['auto', '660px']}
          maxW="100%"
          bg={textBg ? 'rgba(0,0,0,0.5)' : 'transparent'}
          p="10px"
        >
          {heading && (
            <Flex pos="relative" as="header" pl={['20px']}>
              <Box
                pos="absolute"
                top="0"
                bottom="0"
                left="0"
                bg="brand.600"
                w={'11px'}
                style={{ content: '' }}
              />
              <Heading
                as={isFirstOne ? 'h1' : 'h2'}
                fontSize={[30, 70]}
                fontWeight={600}
                color="brand.400"
              >
                {heading}
              </Heading>
            </Flex>
          )}
          {text && (
            <Text
              fontSize={[25, 34]}
              color="brand.800"
              mb={10}
              fontWeight={600}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {ctaText && <CTA text={ctaText} />}
        </Box>
      )}
      {/* {copyright && (
        <Box pos="absolute" right="10px" bottom="10px">
          <Text color="brand.400" fontSize="14px">
            {isUnsplash ? (
              <>
                <ChevronRightIcon />
                <Link target="_blank" href={copyright}>
                  Unsplash
                </Link>
              </>
            ) : (
              copyright
            )}
          </Text>
        </Box>
      )} */}
    </Box>
  );
};

export default forwardRef(Hero);
