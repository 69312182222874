/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';

interface ISeo {
  description?: string;
  lang?: string;
  meta?: Array<object>;
  title: string;
}

function SEO({ description = '', lang = 'de', meta = [], title }: ISeo) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'brainCheck',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        ...meta,
      ]}
    />
  );
}

export default SEO;
