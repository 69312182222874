import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Menu } from './HeaderBar';

export interface IGeneralNavbar {
  refs: HTMLDivElement[];
}

export const GeneralNavbar = ({ refs }: IGeneralNavbar) => {
  return (
    <Flex
      as="nav"
      w="100vw"
      h={['auto', '80px']}
      justifyContent="center"
      alignItems="center"
      flexDir={['column', 'row']}
      mt="1rem"
      borderTop="2px solid black"
      py="1rem"
    >
      <Menu minW={['100%', '600px']} w={['100%', '50%']} bold refs={refs} />
    </Flex>
  );
};
