import { Box, Link } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/layout';
import { Heading } from '@chakra-ui/layout';
import { Flex } from '@chakra-ui/layout';
import { Collapse } from '@chakra-ui/react';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import ReadMoreBtn from './ReadMoreBtn';

export interface IPartnerMember {
  name: string;
  position?: string;
  description: string;
  image: any;
  linkedInLink: string;
  expandText?: string;
  [key: string]: any;
}
export const PartnerMember = ({
  name,
  position,
  description,
  expandText,
  image,
  linkedInLink,
  ...props
}: IPartnerMember) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box as="article" w={['300px']} p="1rem" mb="2rem" mr={['4rem']} {...props}>
      <Box
        minW={['100px', '300px']}
        maxW={['400px', 'auto']}
        height={['300px']}
        mb="1rem"
        sx={{ aspectRatio: '1 / 1' }}
      >
        <GatsbyImage
          image={image}
          alt="Header"
          style={{
            height: '100%',
            width: '100%',
          }}
          objectFit="cover"
          objectPosition="top"
        />
      </Box>
      <Box>
        <Box mb="0.5rem">
          <Heading
            fontFamily="Courier"
            color="brand.600"
            fontSize="25px"
            fontWeight="600"
            as="h2"
            h="60px"
          >
            {name}
          </Heading>
          {position && (
            <Text fontSize="20px" fontWeight="600">
              {position}
            </Text>
          )}
        </Box>
        <Box>
          <Flex justify={linkedInLink ? 'space-between' : 'flex-end'}>
            {linkedInLink && (
              <Box mt="10px">
                <Link href={linkedInLink} target="__blank">
                  <FontAwesomeIcon
                    style={{ fontSize: '24px' }}
                    icon={faLinkedin}
                  />
                </Link>
              </Box>
            )}
            <ReadMoreBtn isOpen={isOpen} onToggle={toggleOpen} />
          </Flex>
          <Collapse in={isOpen}>
            <Text
              fontSize="18px"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {expandText && (
              <>
                <br />
                <Text
                  fontSize="18px"
                  dangerouslySetInnerHTML={{ __html: expandText }}
                />
              </>
            )}
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};
