import { Flex, Link, Box } from '@chakra-ui/layout';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerMember } from './PartnerMember';

const Partner = () => {
  const {
    kemper,
    nReher,
    getz,
    ecker,
    igler,
    sell,
    funke,
    lienhop,
    bdvt,
    afnb,
    expertenclub,
    afan,
    oexperts,
    bdvtoe,
  } = useStaticQuery(graphql`
    query Partner {
      kemper: file(relativePath: { eq: "Team/kemper.jpeg" }) {
        ...Image
      }
      nReher: file(relativePath: { eq: "04-2022/nReher.png" }) {
        ...Image
      }
      getz: file(relativePath: { eq: "11-2022/getz.jpg" }) {
        ...Image
      }
      ecker: file(relativePath: { eq: "11-2022/ecker.jpg" }) {
        ...Image
      }
      igler: file(relativePath: { eq: "11-2022/igler.jpg" }) {
        ...Image
      }
      sell: file(relativePath: { eq: "11-2022/sell.jpg" }) {
        ...Image
      }
      funke: file(relativePath: { eq: "11-2022/funke.jpg" }) {
        ...Image
      }
      lienhop: file(relativePath: { eq: "11-2022/lienhop.jpg" }) {
        ...Image
      }
      afnb: file(relativePath: { eq: "11-2022/afnb.jpeg" }) {
        ...Image
      }
      bdvt: file(relativePath: { eq: "11-2022/bdvt.jpeg" }) {
        ...Image
      }
      bdvtoe: file(relativePath: { eq: "11-2022/bdvt-oe.jpg" }) {
        ...Image
      }
      expertenclub: file(relativePath: { eq: "11-2022/expertenclub.png" }) {
        ...Image
      }
      afan: file(relativePath: { eq: "11-2022/afan.png" }) {
        ...Image
      }
      oexperts: file(relativePath: { eq: "11-2022/oexperts.jpg" }) {
        ...Image
      }
    }
  `);
  const { t } = useTranslation('team');

  return (
    <Flex
      as="section"
      flexDir="column"
      align="center"
      px="10px"
      py={['20px', '50px']}
    >
      <Flex w={['100%', '80%']} justify="center" flexWrap="wrap">
        <PartnerMember
          image={getImage(kemper)}
          mt="0"
          name={t('kemper.name')}
          position={t('kemper.position')}
          description={t('kemper.description')}
          linkedInLink={t('kemper.linkedin')}
        />
        <PartnerMember
          image={getImage(nReher)}
          mt="0"
          name={t('nReher.name')}
          position={t('nReher.position')}
          description={t('nReher.description')}
          expandText={t('nReher.expandText')}
          linkedInLink={t('nReher.linkedin')}
        />
        <PartnerMember
          image={getImage(getz)}
          mt="0"
          name={t('getz.name')}
          position={t('getz.position')}
          description={t('getz.description')}
          expandText={t('getz.expandText')}
          linkedInLink={t('getz.linkedin')}
        />
        <PartnerMember
          image={getImage(ecker)}
          mt="0"
          name={t('ecker.name')}
          position={t('ecker.position')}
          description={t('ecker.description')}
          expandText={t('ecker.expandText')}
          linkedInLink={t('ecker.linkedin')}
        />
        <PartnerMember
          image={getImage(igler)}
          mt="0"
          name={t('igler.name')}
          position={t('igler.position')}
          description={t('igler.description')}
          expandText={t('igler.expandText')}
          linkedInLink={t('igler.linkedin')}
        />
        <PartnerMember
          image={getImage(sell)}
          mt="0"
          name={t('sell.name')}
          position={t('sell.position')}
          description={t('sell.description')}
          expandText={t('sell.expandText')}
          linkedInLink={t('sell.linkedin')}
        />
        <PartnerMember
          image={getImage(funke)}
          mt="0"
          name={t('funke.name')}
          position={t('funke.position')}
          description={t('funke.description')}
          expandText={t('funke.expandText')}
          linkedInLink={t('funke.linkedin')}
        />
        <PartnerMember
          image={getImage(lienhop)}
          mt="0"
          name={t('lienhop.name')}
          position={t('lienhop.position')}
          description={t('lienhop.description')}
          expandText={t('lienhop.expandText')}
          linkedInLink={t('lienhop.linkedin')}
        />
      </Flex>
      <Flex w={['100%', '80%']} justify="center" flexWrap="wrap" align="center">
        <Box w="200px" mx="1rem" mb="1rem">
          <Link href="http://www.expertenclub.de/" target="__blank">
            <GatsbyImage
              image={getImage(expertenclub) as IGatsbyImageData}
              alt="Header"
              style={{
                height: '100%',
                width: '100%',
              }}
              objectFit="contain"
              objectPosition="center"
            />
          </Link>
        </Box>
        <Box w="200px" mx="1rem" mb="1rem">
          <Link href="http://www.bdvt.de/" target="__blank">
            <GatsbyImage
              image={getImage(bdvt) as IGatsbyImageData}
              alt="Header"
              style={{
                width: '100%',
              }}
              objectFit="contain"
              objectPosition="center"
            />
          </Link>
        </Box>
        <Box w="200px" mx="1rem" mb="1rem">
          <Link href="http://www.bdvt.de/" target="__blank">
            <GatsbyImage
              image={getImage(bdvtoe) as IGatsbyImageData}
              alt="Header"
              style={{
                width: '100%',
              }}
              objectFit="contain"
              objectPosition="center"
            />
          </Link>
        </Box>
        <Box w="200px" mx="1rem" mb="1rem">
          <GatsbyImage
            image={getImage(oexperts) as IGatsbyImageData}
            alt="Header"
            style={{
              width: '100%',
            }}
            objectFit="contain"
            objectPosition="center"
          />
        </Box>
        <Box w="350px" mx="1rem" mb="1rem">
          <Link href="https://afan.academy/" target="__blank">
            <GatsbyImage
              image={getImage(afan) as IGatsbyImageData}
              alt="Header"
              style={{
                height: '100%',
                width: '100%',
              }}
              objectFit="contain"
              objectPosition="center"
            />
          </Link>
        </Box>
        <Box w="200px" mx="1rem" mb="1rem">
          <Link href="http://www.afnb-international.com/" target="__blank">
            <GatsbyImage
              image={getImage(afnb) as IGatsbyImageData}
              alt="Header"
              style={{
                height: '100%',
                width: '100%',
              }}
              objectFit="contain"
              objectPosition="center"
            />
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Partner;
