import { ButtonProps } from '@chakra-ui/button';
import { Button } from '@chakra-ui/button';
import { Link } from '@chakra-ui/layout';
import { Center } from '@chakra-ui/layout';
import React from 'react';

export interface ICTA extends ButtonProps {
  text: string;
}

const CTA = ({ text, ...props }: ICTA) => {
  return (
    <Center>
      <Link href="mailto:happy@braincheck.de" target="_blank">
        <Button
          w={['auto']}
          borderRadius="0"
          border="4px solid"
          borderColor="brand.400"
          bg="brand.600"
          height={['65px', '65px']}
          color="brand.400"
          _hover={{ backgroundColor: 'brand.500' }}
          fontSize={[18, 24]}
          style={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          }}
          {...props}
        >
          {text}
        </Button>
      </Link>
    </Center>
  );
};

export default CTA;
