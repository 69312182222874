import { ArrowUpIcon } from '@chakra-ui/icons';
import { Button, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ScrollButton = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('common');

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <Button
      pos="fixed"
      right="2rem"
      zIndex="20"
      bottom="2rem"
      transition="all 0.2s"
      style={{ opacity: visible ? '1' : '0' }}
      border="4px solid"
      borderColor="brand.600"
      w="4.5rem"
      h="4.5rem"
      bg="brand.700"
      textAlign="center"
      p="0"
      color="grey"
      _hover={{ color: 'black' }}
    >
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        w="100%"
        h="100%"
      >
        <ArrowUpIcon boxSize="2rem" color="brand.600" onClick={scrollToTop} />
        <Text
          w="100%"
          whiteSpace="normal"
          fontSize="10px"
          wordBreak="break-word"
          color="inherit"
        >
          {t('labels.backtotop')}
        </Text>
      </Flex>
    </Button>
  );
};
