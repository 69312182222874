import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex, Text } from '@chakra-ui/layout';
import { Heading } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/layout';
import { Collapse } from '@chakra-ui/transition';
import React, { forwardRef, ReactElement } from 'react';
import CTA from './CtaButton';
import ReadMoreBtn from './ReadMoreBtn';

export interface ITextBlock {
  heading?: string;
  text?: string | ReactElement;
  ctaText?: string;
  forceCenter?: boolean;
  expandComponent?: ReactElement;
  expandBg?: string;
  [key: string]: any;
}

const TextBlock = (
  {
    heading,
    text,
    ctaText,
    forceCenter,
    expandComponent,
    expandBg,
    children,
    ...rest
  }: ITextBlock,
  ref,
) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Box
        as="article"
        px={['20px', '86px']}
        py={['20px', '58px']}
        bgColor="brand.500"
        ref={ref}
        {...rest}
      >
        {heading && (
          <Heading
            fontSize={['20px', '30px']}
            fontFamily="Courier"
            color="brand.400"
            textAlign={{
              base: 'center',
              md: !text || forceCenter ? 'center' : 'left',
            }}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        {React.isValidElement(text) && text}
        {!React.isValidElement(text) && (
          <Text
            fontSize={['16px', '20px']}
            color="brand.400"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        {ctaText && <CTA mt="30px" text={ctaText} />}
        {expandComponent && <ReadMoreBtn onToggle={onToggle} isOpen={isOpen} />}
        {children}
      </Box>
      {expandComponent && (
        <Box
          as="article"
          px={['20px', '86px']}
          pb={isOpen ? ['20px', '58px'] : 0}
          bgColor={expandBg || 'brand.500'}
        >
          <Collapse in={isOpen}>{expandComponent}</Collapse>
        </Box>
      )}
    </>
  );
};

export default forwardRef(TextBlock);
