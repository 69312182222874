import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ReadMoreBtn = ({ onToggle, isOpen }) => {
  const { t } = useTranslation('common');
  return (
    <Flex justifyContent="flex-end">
      <Button
        display={['none', 'block']}
        _focus={{ border: 'none' }}
        _active={{ border: 'none' }}
        bg="none"
        color="brand.600"
        onClick={onToggle}
      >
        {isOpen ? t('labels.readLess') : t('labels.readMore')}
      </Button>
      <Button
        display={['block', 'none']}
        _focus={{ border: 'none' }}
        _active={{ border: 'none' }}
        _hover={{ border: 'none' }}
        bg="none"
        color="brand.600"
        onClick={onToggle}
      >
        {isOpen ? t('labels.readLess') : t('labels.readMore')}
      </Button>
    </Flex>
  );
};

export default ReadMoreBtn;
