import { Text } from '@chakra-ui/layout';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';

export interface IMaps {
  height: string;
  width?: string;
}

const Maps = ({ height, width }: IMaps) => {
  const { isLoaded, loadError } = useJsApiLoader({
    language: 'de',
    id: 'myMap',
    googleMapsApiKey: 'AIzaSyARfhOxUev5CPZUV0FB2_jURPNmCv4M0iI',
  });
  const mapOptions = {
    zoom: 14,
    center: { lat: 50.928282, lng: 6.797013 },
    mapContainerStyle: {
      height,
      width,
    },
  };
  const markerOptions = {
    position: { lat: 50.928282, lng: 6.797013 },
  };

  const map = () => (
    <GoogleMap {...mapOptions}>
      <Marker {...markerOptions} />
    </GoogleMap>
  );

  if (loadError) {
    return <Text>Da ist etwas schief gelaufen...</Text>;
  }

  return isLoaded ? map() : <Text>Loading...</Text>;
};

export default Maps;
